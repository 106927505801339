<template> 
    <div class="body">
      <div>
        <v-container>
          <v-toolbar flat dense>
            <v-spacer />
            <c-date-picker  class="mr-2"
              append-icon="mdi-calendar"
              :label="$t('plannerCodeRange.fromDate')"
              dense outlined  hide-details="auto"
              @input="getPlannerCode()"
              v-model="plannerCodeRange.fromDate">
            </c-date-picker>
             <c-date-picker
              class="mr-2"
              append-icon="mdi-calendar"
              :label="$t('plannerCodeRange.toDate')"
              dense
              outlined
              :max="monthRestriction"
              @input="getPlannerCode()"
              v-model="plannerCodeRange.toDate"
              hide-details="auto"
            ></c-date-picker>
              <v-autocomplete
              v-if="user.User_Category == 'admin' || user.User_Category == 'GC Admin'" 
              outlined
              hide-details="auto"
              dense
              :items="SalesOfficeArr"
              :loading="SalesLoading"
              style="max-width: 25%"
              v-model="SO"
              :label="$t('PlannersInfo.SalesOfficeName')"
              item-text="SalesOfficeData"
              item-code="SalesOfficeData"
              return-object
              @change="getPlannerData()"
              >
              </v-autocomplete>

             <v-autocomplete
              class="mr-2"
              outlined
              maxlength="5"
              dense
              :loading="plannersLoading"
              :items="planners"
              hide-details="auto"
              item-text="EmployeesData"
              item-code="EmployeesData"
             :label="$t('plannerCodeRange.PlannerCode')"
              v-model="plannerCodeRange.Planner"
              style="max-width: 20%"
              return-object 
              @change="cadData.length > 0 ? specificPlanner(plannerCodeRange) : ''"
              >
            </v-autocomplete>

            <v-btn
              class="mr-5"
              @click="specificPlanner(plannerCodeRange)"
              xxl
              :style="{ color: 'white' }"
              :disabled="loading || !plannerCodeRange.Planner.EmployeesData"
              Length="5"
              color="#0D47A1"
              >{{$t(`generate.generate`)}}</v-btn>

             <v-btn
              v-if="cadData.length > 0"
              @click="printCSV()"
              xxl
              :style="{ color: 'white' }"
              color="#0D47A1"
              >{{$t(`generate.generateCSV`)}}</v-btn>

            <v-spacer/>
          </v-toolbar>
        </v-container>
      </div>
      <div class="pr-6 pl-6">
        <v-card flat v-if="cadData.length > 0">
          <v-simple-table dense>
            <thead>
              <tr>
                <th colspan="4"
                  style="background-color: #01579b; color: white"
                  class="text-center TableStyle"
                >
                 {{ $t(`PlannersInfo.SalesOfficeName`)}}
                </th>
                <th colspan="4"
                  style="background-color: #01579b; color: white"
                  class="text-center TableStyle"
                >
                {{ $t(`PlannersInfo.BranchName`)}}
                </th>
                <th colspan="4"
                  style="background-color: #01579b; color: white"
                  class="text-center TableStyle"
                >
                {{ $t(`PlannersInfo.PlannersName`)}}
                </th>
              </tr>
            </thead>
            <tbody style="background-color: #e8eaf6">
              <tr >
                <td  colspan="4" class="text-center TableStyle">{{ EmpInfo.SalesOfficeName }}</td>
                <td  colspan="4" class="text-center TableStyle">{{ EmpInfo.BranchName }}</td>
                <td  colspan="4" class="text-center TableStyle">{{ EmpInfo.EmployeeName}}</td>
              </tr>
            </tbody>
          </v-simple-table >
        </v-card>
      </div>

      <div v-if="cadData.length > 0" class="pl-6 pr-6">
        <v-card class="mt-2" flat>
          <v-simple-table id="tableRef" :height="plannerData.tableHeight" dense :style="{'borderBottom': plannerData.scroll? '1px solid black' : ''}">
            <thead id="tHead1" class="sticky-thead" ref="firstThead" style="top: 0px;">
              <tr>
                <th class="text-center header TableStyle" colspan="3"><h3>{{$t(`header.Category`)}}</h3></th>
                <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" colspan="2">{{$t(`header.CONTRACT`)}}</th>
                <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" colspan="2">{{$t(`header.STOP CHECK`)}}</th>
                <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" colspan="2">{{$t(`header.FINAL PLAN`)}}</th>
                <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" colspan="2">{{$t(`header.JOUTOU`)}}</th>
              </tr>
            </thead>
            <thead id="tHead2" class="sticky-thead" :style="{ top: secondTheadTop + 'px' }" ref="secondThead">
              <tr v-for="(item,i) in average" :key="i">
              <template v-if="i != 1 && i !=2" >
                <th class="text-center header TableStyle" 
                  colspan="3"
                  ><h3>{{ $t(`Average.${item.title}`)}}</h3>
                </th>
                <th class="text-center TableStyle" 
                  :style="{ 'color' : item.decidedland > 100 && i==6 ? 'red' : ''}"
                  colspan="2"
                >
                  <h4 >{{ `${i==2 ? item.decidedland+ '/'+branchLength.length : i==4 ?  item.decidedland+ '/'+salesLength.length : i==6 ?  item.decidedland+ '/'+NationLength.length : item.decidedland }`}}</h4>
                </th>
                <th 
                  class="text-center TableStyle" 
                  :style="{ 'color' : item.stopcheck > 100 && i==6 ? 'red' : ''}"
                  colspan="2"
                >
                  <h4>{{ `${i==2 ? item.stopcheck+ '/'+branchLength.length : i==4 ?  item.stopcheck+ '/'+salesLength.length : i==6 ?  item.stopcheck+ '/'+NationLength.length : item.stopcheck }`}}</h4>
                </th>
                <th class="text-center TableStyle" 
                  :style="{ 'color' : item.finalPlan > 100 && i==6 ? 'red' : ''}"
                  colspan="2"
                >
                  <h4>{{ `${i==2 ? item.finalPlan+ '/'+branchLength.length : i==4 ?  item.finalPlan+ '/'+salesLength.length : i==6 ?  item.finalPlan+ '/'+NationLength.length : item.finalPlan }`}}</h4>
                </th>
                <th class="text-center TableStyle" 
                  :style="{ 'color' : item.joutou > 100 && i==6 ? 'red' : ''}"
                  colspan="2"
                >
                  <h4>{{ `${i==2 ? item.joutou+ '/'+branchLength.length : i==4 ?  item.joutou+ '/'+salesLength.length : i==6 ?  item.joutou+ '/'+NationLength.length : item.joutou }`}}</h4>
                </th>
              </template>
              </tr>
              <tr>
                <td colspan="12" style="height: 10px"></td>
              </tr>
              <tr>
                <th
                  class="text-center header TableStyle" 
                  colspan="3"
                  id="th1"
                >
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on">
                        <h2>  {{  $t(`header.NoofKAkou`) }} : {{cadData.length}}</h2> 
                      </span>
                    </template>
                      <span> {{cadData.length}}</span>
                  </v-tooltip>
                </th>
                <th
                  class="text-center header TableStyle" 
                  colspan="8"
                >{{$t(`header.No of Days`)}}
                </th>
              </tr>
              <tr>
                <th
                  class="text-center header TableStyle" 
                  colspan="1"
                >{{$t(`header.CustomerCode`)}}
                </th>
                <th
                  class="text-center header TableStyle" 
                  colspan="2"
                >{{$t(`header.BuildingType`)}}
                </th>
                <th
                  class="text-center header TableStyle" 
                  colspan="2" 
                  id="th2"
                >
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                    <span
                    v-bind="attrs"
                      v-on="on">
                        {{  $t(`header.CONTRACT`) }}
                    </span>
                    </template>
                    <span> No of days from decision land date to kakou irai</span>
                  </v-tooltip>
                </th>
                <th 
                  class="text-center header TableStyle" 
                  colspan="2" 
                  id="th3"
                >
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                    <span
                    v-bind="attrs"
                      v-on="on">
                      {{  $t(`header.STOP CHECK`) }}
                    </span>
                    </template>
                    <span> STOPチェック依頼～工場加工依頼までの日数</span>
                  </v-tooltip>
                </th>
                <th 
                  class="text-center header TableStyle pa-1" 
                  colspan="2" 
                  id="th4"
                >
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                    <span
                    v-bind="attrs"
                      v-on="on">
                      {{  $t(`header.FINAL PLAN`) }}
                    </span>
                    </template>
                    <span> 着手準備依頼１回目～工場加工依頼までの日数</span>
                  </v-tooltip>
                </th>
                <th 
                  class="text-center header TableStyle" 
                  colspan="2"
                  id="th5" 
                >
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                    <span
                    v-bind="attrs"
                      v-on="on">
                    {{  $t(`header.JOUTOU`) }}
                    </span>
                    </template>
                    <span> 工場加工依頼～上棟日までの日数</span>
                  </v-tooltip>
                </th>
              </tr>
            </thead>
            <tbody id="tBody">
              <tr v-for="(item,x) in cadData" :key="x">
                <td
                  @mouseenter="hoverColumn(true, x)"
                  @mouseleave="hoverColumn(false, x)"
                  class="text-center TableStyle pr-0 pl-10"
                  colspan="1" 
                  :style="{ width: plannerData.columnWidth.width2, maxWidth: plannerData.columnWidth.width2}"
                >
                  <span :id="'constructionCode-' + x">
                    {{ item.ConstructionCode }}
                  </span>
                  <v-btn class="mr-1 ml-4" @click="copyText(item.ConstructionCode, x)" x-small icon>
                    <v-fade-transition>
                      <v-icon v-show="copyIcon[x]">{{showText[x]? 'mdi-check': 'mdi-content-copy'}}</v-icon>
                    </v-fade-transition>
                  </v-btn>
                  <v-tooltip v-model="showText[x]" right transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <span v-on="on"></span>
                    </template>
                    <span>Copied!</span>
                  </v-tooltip>
                </td>
                <td
                  class="text-center header2 TableStyle"
                  colspan="2" 
                  :style="{ width: plannerData.columnWidth.width2, maxWidth: plannerData.columnWidth.width2}"
                >
                  <v-tooltip right color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="display: block;"
                        v-bind="attrs"
                          v-on="on">
                            {{Array.isArray(splitChar(item.ConstructionTypeName)) ? splitChar(item.ConstructionTypeName)[0] : splitChar(item.ConstructionTypeName)}}
                            <!-- {{item.ConstructionTypeName}} -->
                      </span>
                      <span
                        style="display: block;"
                        v-bind="attrs"
                          v-on="on">
                            {{Array.isArray(splitChar(item.ConstructionTypeName)) ? splitChar(item.ConstructionTypeName)[1] : ''}}
                      </span>
                    </template>
                    <span class="pa-1"> {{item.HouseType}}</span>
                  </v-tooltip>
                </td>
                <td colspan="2" class="text-center TableStyle">{{`${item.decidedlandDays == 0 || item.decidedlandDays == null ? '':item.decidedlandDays}`}}</td>
                <td colspan="2" class="text-center TableStyle">{{`${item.stopcheck == 0 ?'' :item.stopcheck}`}}</td>
                <td colspan="2" class="text-center TableStyle">{{`${item.finalPlan == 0 ?'': item.finalPlan}`}}</td>
                <td colspan="2" class="text-center TableStyle">{{`${item.JoutouDays == 0  ||item.JoutouDays == null || item.JoutouDays ==''? '' :item.JoutouDays}`}}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>

    <div v-if="!loading && cadData.length == 0">
        <Logo />
    </div>

    <div v-else class="center" >
      <v-overlay :value="loading" color="#000000">
        <v-progress-circular
          :width="10"
          color="primary"
          indeterminate
          :size="70"
        >
        </v-progress-circular>
        <div>{{'ローディング中....'}}</div> 
      </v-overlay>
    </div>
    
    </div>
</template>
<script>
import CDatePicker from "../components/CDatePicker.vue";
import moment from 'moment';
import Swal from "sweetalert2";
import _ from 'lodash';
import axios from 'axios';
import SalesOfficeClass from '../assets/class/salesOffice'
import PlannerCodeClass from '../assets/class/PlannersCode'
import PlannerData from '../assets/class/PlannerData/PlannerData.js'
import Logo from "../components/Logo.vue";
    export default {
          components: { CDatePicker, Logo },
        data() {
            return {
              plannerData: new PlannerData(),
              salesOfficefunction: new SalesOfficeClass(),
              plannersCodefunction: new PlannerCodeClass(),
              plannerCodeRange: {
                      fromDate: moment().subtract(365,'days').format('YYYY-MM-DD'),
                      toDate: moment().format('YYYY-MM-DD'),
                      // fromDate:'2021-03-15',
                      // toDate:'2022-03-15',
                      Planner: {},
                  },
                average:[ 
                        {title:'Average Number of Days',decidedland:0,stopcheck:0,finalPlan:0,joutou:0,},
                        {title:'TENJIJO Average',decidedland:0,stopcheck:0,finalPlan:0,joutou:0},
                        {title:'TENJIJO Ranking',decidedland:'',stopcheck:'',finalPlan:'',joutou:''},
                        {title:'EIGYOUSYO Average' ,decidedland:0,stopcheck:0,finalPlan:0,joutou:0},
                        {title:'EIGYOUSYO Ranking',decidedland:'',stopcheck:'',finalPlan:'',joutou:''},
                        {title:'ZENKOKU Average',decidedland:0,stopcheck:0,finalPlan:0,joutou:0},
                        {title:'ZENKOKU Ranking',decidedland:'',stopcheck:'',finalPlan:'',joutou:''},
                      ],
                user:{},
                planners:[],
                loading:false,
                plannersLoading:false,
                EmpInfo: {},
                cadData:[],
                SO:'',
                SalesOfficeArr:[],
                branchLength:[],
                salesLength:[],
                NationLength:[],
                SalesLoading:false,
                showText:{},
                copyIcon: [],
                lastCopiedIndex: null,
                secondTheadTop: 0
              }
        },
        methods: {
            tofixed2(num){
              return (Math.round((num ) * 10) / 10).toFixed(1)
            },
            printCSV(){
              let test = this.cadData.map(a=>{
                return {
                          ConstructionCode:a.ConstructionCode,
                          HouseType:a.ConstructionTypeName,
                          ['No of Days of Decidedland']:a.decidedlandDays,
                          ['No of Days of StopCheck']:a.stopcheck,
                          ['No of Days of FinalPlan']:a.finalPlan,
                          ['No of Days of Joutou']:a.JoutouDays,
                      }
              })
              let headers = [
                {text: this.$t('header.CustomerCode'), value: "ConstructionCode"},
                {text: this.$t('header.BuildingType'), value: "HouseType"},
                {text: this.$t('header.CONTRACT'), value: "No of Days of Decidedland"},
                {text: this.$t('header.STOP CHECK'), value: "No of Days of StopCheck"},
                {text: this.$t('header.FINAL PLAN'), value: "No of Days of FinalPlan"},
                {text: this.$t('header.JOUTOU'), value: "No of Days of Joutou"},
              ]
              let csv = ""
            //header
            for(let header of headers){
              csv += header.text + ","
            }
              csv+="\n"
            //items
            for(let item of test){
                
              for(let header of headers){
                if(item[header.value] ==null || item[header.value] ==0){
                  item[header.value] ="  "
                }
                csv+= item[header.value] + ","
              }
                csv+="\n"
            }
              csv+="\n"
            let filename = "IchijoSekkeiData-PlannerJobData-1.csv"
         var BOM = "\uFEFF";
            var   csvData = BOM + csv;
        var blob = new Blob([csvData], { type: 'text/csv;charset=ISO-2022-JP;' });
              if (navigator.msSaveBlob) { // IE 10+
                  navigator.msSaveBlob(blob, filename);
              } 
              else {
                  var link = document.createElement("a");
                  if (link.download !== undefined) { // feature detection
                      // Browsers that support HTML5 download attribute
                      var url = URL.createObjectURL(blob);
                      link.setAttribute("href", url);
                      link.setAttribute("download", filename);
                      link.style.visibility = 'hidden';
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                  }
              }
              //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
               let csv2=""
               let headers2 = [
                {text: this.$t('header.Category')},
                {text: this.$t('header.CONTRACT'), value: "No of Days of Decidedland"},
                {text: this.$t('header.STOP CHECK'), value: "No of Days of StopCheck"},
                {text: this.$t('header.FINAL PLAN'), value: "No of Days of FinalPlan"},
                {text: this.$t('header.JOUTOU'), value: "No of Days of Joutou"},
              ]
                for(let header of headers2){
                  csv2 += header.text + ","
                }
                  csv2+="\n"
              let summaryHeaders = [
                { text: 'title', value: 'title'},
                {text: "decidedland", value: "decidedland" },
                {text: "finalPlan", value: "finalPlan"},
                {text: "stopcheck", value: "stopcheck"},
                {text: "joutou", value: "joutou"},
              ]
              // for(let item of this.average){
            let aveHolder= JSON.parse(JSON.stringify(this.average))
              for(let i=0; i<=aveHolder.length-1;i++){
                if(i == 1 ){
                  aveHolder.splice(i,2)
                }   
                for(let header of summaryHeaders){
                  if (header.text == 'title') {
                    const translationKey = `Average.${aveHolder[i].title}`;
                    aveHolder[i][header.value] = this.$t(translationKey);
                  }
                  if(i == 2 && header.text != 'title'){ 
                    aveHolder[i][header.value] =  ` ${aveHolder[i][header.value]}/ ${this.salesLength.length}`.replace("'", "")
                  }
                  if(i == 4 && header.text != 'title'){
                    aveHolder[i][header.value] =   ` ${aveHolder[i][header.value]}/ ${this.NationLength.length}`.replace("'", "")
                  }
                  csv2+= (aveHolder[i][header.value] ?? "") + ","
                }
                csv2+="\n"
              } 
           let filename2 = "IchijoSekkeiData-PlannerJobData-2.csv"
           
           var   csvData2 = BOM + csv2;
           var blob2 = new Blob([csvData2], { type: 'text/csv;charset=ISO-2022-JP;' });
              if (navigator.msSaveBlob) { // IE 10+
                  navigator.msSaveBlob(blob2, filename2);
              } 
              else {
                  var link2 = document.createElement("a");
                  if (link2.download !== undefined) { // feature detection
                      var url2 = URL.createObjectURL(blob2);
                      link2.setAttribute("href", url2);
                      link2.setAttribute("download", filename2);
                      link2.style.visibility = 'hidden';
                      document.body.appendChild(link2);
                      link2.click();
                      document.body.removeChild(link2);
                  }
              }
            },
            specificPlanner(item) {
              if(item.Planner.SalesOfficeCode == undefined ){
                Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: `Planner ${this.userInfo.Emp_code} dont have Data`,
                      });
                return false
              }
                this.datePicker = {
                  fromDate:item.fromDate,
                  toDate:item.toDate,
                  BranchCode:item.Planner.ContractExhibitionCode,
                  BranchName:item.Planner.ContractExhibitionName,
                  EmployeeCode:item.Planner.EmployeeCode,
                  EmployeeName:item.Planner.EmployeeName,
                  SalesOfficeCode:item.Planner.SalesOfficeCode,
                  SalesOfficeName:item.Planner.SalesOfficeName
                };
                  this.loadData();
            },
            test(data){
              let planner=[]
              let test=[]
              data.forEach(x=>{
                let index = planner.findIndex(y=> y.EmployeeCode==x.EmployeeCode)
                if(index==-1){
                  planner.push({EmployeeCode:x.EmployeeCode})
                }
              })
              planner.forEach(rec=>{
                let arr=[]
                data.forEach(rec1=>{ 
                  if(rec.EmployeeCode == rec1.EmployeeCode){
                    arr.push(rec1)
                  }
                })
                test.push(arr)
              })
              return(test)
            },
            async loadData() {
              try {
              this.loading=true
            if( parseInt(moment.duration(moment(this.datePicker.toDate,"YYYY-MM-DD").diff(moment(this.datePicker.fromDate,"YYYY-MM-DD"))).asDays()) <= 365   ){
              this.EmpInfo=this.datePicker
              this.cadData=[]
              let branchesData=[]
              let saleData=[]
              this.average=[ 
                        {title:'Average Number of Days',decidedland:0,stopcheck:0,finalPlan:0,joutou:0,},
                        {title:'TENJIJO Average',decidedland:0,stopcheck:0,finalPlan:0,joutou:0},
                        {title:'TENJIJO Ranking',decidedland:'',stopcheck:'',finalPlan:'',joutou:''},
                        {title:'EIGYOUSYO Average',decidedland:0,stopcheck:0,finalPlan:0,joutou:0},
                        {title:'EIGYOUSYO Ranking',decidedland:'',stopcheck:'',finalPlan:'',joutou:''},
                        {title:'ZENKOKU Average',decidedland:0,stopcheck:0,finalPlan:0,joutou:0},
                        {title:'ZENKOKU Ranking',decidedland:'',stopcheck:'',finalPlan:'',joutou:''},
                      ],
                await axios.post(`${this.aws}getDaysData`,this.EmpInfo,this.apiKey).then(res=>{
                if(res.data){
                    let newMerge=this.test(res.data)
                    let overall=[{countDocu:res.data,plannerCode:newMerge}]
                    let brnch =  this.planners.filter(r=>{
                      if(this.datePicker.BranchCode == r.AssignedExhibitionCode){
                          return r
                      }
                    })
                    let SlsOFFc = this.planners.filter(r=>{
                      if(this.datePicker.SalesOfficeCode == r.SalesOfficeCode){
                          return r
                      }
                    })
                      overall[0].countDocu.filter(x=>{
                        if(x.EmployeeCode==`${this.datePicker.EmployeeCode}` ){
                          this.cadData.push(x)
                        }
                        brnch.filter(r=>{
                          if(x.EmployeeCode == r.EmployeeCode){
                            branchesData.push(x)
                          }
                        })
                        SlsOFFc.filter(r=>{
                          if(x.EmployeeCode == r.EmployeeCode){
                            saleData.push(x)
                          }
                        })
                      })
               

                        


                let category = ['decidedland','stopcheck','finalPlan','joutou']
                if(this.cadData.length > 0){
                  for(let i=0;i<=this.cadData.length-1;i++){             
                    this.average[0].decidedland+=parseInt(this.cadData[i].decidedlandDays ? this.cadData[i].decidedlandDays:0)
                    this.average[0].stopcheck+=parseInt(this.cadData[i].stopcheck?this.cadData[i].stopcheck:0)
                    this.average[0].finalPlan+=parseInt(this.cadData[i].finalPlan?this.cadData[i].finalPlan:0)
                    this.average[0].joutou+=parseInt(this.cadData[i].JoutouDays?this.cadData[i].JoutouDays:0)
                 } 
                    this.average[0].decidedland = this.tofixed2((this.average[0].decidedland / this.cadData.length))
                    this.average[0].stopcheck =  this.tofixed2((this.average[0].stopcheck / this.cadData.length))
                    this.average[0].finalPlan =  this.tofixed2((this.average[0].finalPlan / this.cadData.length))
                    this.average[0].joutou =  this.tofixed2((this.average[0].joutou / this.cadData.length))
                    this.loading=false
                }
                else{
                    Swal.fire({
                      icon: "error",
                      title: `${this.$i18n.locale =='ja'?'エラー' :'Error' }`,
                      text: `No Plan, No Kakouirai`,
                    })
                    this.loading = false;
                    return false
                }
// console.log(this.average);
                let plann=[]
                let plann2=[]
                overall[0].plannerCode.forEach(val => {
                    let a=[]
                    let b=[]
                    val.filter(x=>{
                        brnch.filter(r=>{
                          if(x.EmployeeCode == r.EmployeeCode){
                            a.push(x)
                          }
                        })
                        SlsOFFc.filter(r=>{
                          if(x.EmployeeCode == r.EmployeeCode){
                            b.push(x)
                          }
                        })
                      
                      })
                      if(a.length > 0){ 
                       plann.push(a)
                      }
                      if(b.length > 0){
                        plann2.push(b)
                      }
                   }); 
// branches
                 for(let x=0;x<=branchesData.length-1;x++){
                    if(branchesData[x].decidedlandDays){
                     this.average[1].decidedland+=parseInt(branchesData[x].decidedlandDays)
                    }
                    if(branchesData[x].stopcheck){
                      this.average[1].stopcheck+=parseInt(branchesData[x].stopcheck)
                    }
                    if(branchesData[x].finalPlan){
                      this.average[1].finalPlan+=parseInt(branchesData[x].finalPlan)
                    }
                    if(branchesData[x].JoutouDays){
                     this.average[1].joutou+=parseInt(branchesData[x].JoutouDays)
                    }
                
                  }
                  this.average[1].decidedland =  Math.ceil((this.average[1].decidedland / branchesData.length))
                  this.average[1].stopcheck =  Math.ceil((this.average[1].stopcheck / branchesData.length))
                  this.average[1].finalPlan =  Math.ceil((this.average[1].finalPlan / branchesData.length))
                  this.average[1].joutou =  Math.ceil((this.average[1].joutou / branchesData.length))
                 let arrt=[]
                    for(let x=0;x<=plann.length-1;x++){
                      let obj={plannCode:'',decidedland:0,stopcheck:0,finalPlan:0,joutou:0}
                        if(plann[x]){
                          for(let i=0;i<=plann[x].length-1;i++){
                            obj.plannCode=plann[x][0].EmployeeCode
                            obj.decidedland+=plann[x][i].decidedlandDays?parseInt(plann[x][i].decidedlandDays):0
                            obj.stopcheck+=plann[x][i].stopcheck?parseInt(plann[x][i].stopcheck):0
                            obj.finalPlan+=plann[x][i].finalPlan?parseInt(plann[x][i].finalPlan):0
                            obj.joutou+=plann[x][i].JoutouDays?parseInt(plann[x][i].JoutouDays):0

                          }
                          obj.decidedland = parseFloat(this.tofixed2((obj.decidedland / plann[x].length)))
                          obj.stopcheck = parseFloat(this.tofixed2((obj.stopcheck / plann[x].length)))
                          obj.finalPlan = parseFloat(this.tofixed2((obj.finalPlan / plann[x].length)))
                          obj.joutou =parseFloat( this.tofixed2((obj.joutou / plann[x].length)))
                      }
                      arrt.push(obj)
                    }
                    for(let x =0; x<= category.length -1; x++){
                      this.branchLength=  _.orderBy(arrt ,[`${category[x]}`] ,['asc'])
                      for(let i =0 ; i <= this.branchLength.length-1;i++){
                        if(this.branchLength[i].plannCode==`${this.datePicker.EmployeeCode}`){
                            this.average[2][`${category[x]}`]  = i+1 
                        }
                      }
                    }
//sales Office    
                    for(let x=0;x<=saleData.length-1;x++){
                        this.average[3].decidedland+=parseInt(saleData[x].decidedlandDays?saleData[x].decidedlandDays:0)
                        this.average[3].stopcheck+=parseInt(saleData[x].stopcheck?saleData[x].stopcheck:0)
                        this.average[3].finalPlan+=parseInt(saleData[x].finalPlan?saleData[x].finalPlan:0)
                        this.average[3].joutou+=parseInt(saleData[x].JoutouDays?saleData[x].JoutouDays:0)
                    }
                      this.average[3].decidedland =   this.tofixed2((this.average[3].decidedland / saleData.length))
                      this.average[3].stopcheck =   this.tofixed2((this.average[3].stopcheck / saleData.length))
                      this.average[3].finalPlan =   this.tofixed2((this.average[3].finalPlan / saleData.length))
                      this.average[3].joutou =   this.tofixed2((this.average[3].joutou / saleData.length))
                  let arrt2=[]
                  for(let x=0;x<=plann2.length-1;x++){
                  let obj2={plannCode:'',decidedland:0,stopcheck:0,finalPlan:0,joutou:0}
                        if(plann2[x]){
                          for(let i=0;i<=plann2[x].length-1;i++){
                            obj2.plannCode=plann2[x][0].EmployeeCode
                            obj2.decidedland+=plann2[x][i].decidedlandDays?parseInt(plann2[x][i].decidedlandDays):0
                            obj2.stopcheck+=plann2[x][i].stopcheck?parseInt(plann2[x][i].stopcheck):0
                            obj2.finalPlan+=plann2[x][i].finalPlan?parseInt(plann2[x][i].finalPlan):0
                            obj2.joutou+=plann2[x][i].JoutouDays?parseInt(plann2[x][i].JoutouDays):0
                          }
                          obj2.decidedland = parseFloat(this.tofixed2((obj2.decidedland / plann2[x].length)))
                          obj2.stopcheck = parseFloat(this.tofixed2((obj2.stopcheck / plann2[x].length)))
                          obj2.finalPlan = parseFloat(this.tofixed2((obj2.finalPlan / plann2[x].length)))
                          obj2.joutou = parseFloat(this.tofixed2((obj2.joutou / plann2[x].length)))
                      } 
                      arrt2.push(obj2)
                    }
                      for(let x =0; x<= category.length -1; x++){
                      //  console.log('----------------------',category[x],'--------------------------')
                       this.salesLength =  _.orderBy(arrt2 ,[`${category[x]}`] ,['asc'])
                            let arr=this.salesLength.reduce((total,item)=>{
                              let index =total.findIndex(y=> y.value == item[`${category[x]}`] ) 
                              if(index > -1 ){
                                  total[index].data.push(item)
                                return total
                              }
                              else{
                                total.push(
                                {
                                  value:item[`${category[x]}`],
                                  data:[item]
                                }
                                )
                                return total
                              }
                          },[])
                          let ctr = 0 
                          arr.filter((rec,index)=>{
                            rec.data.forEach((r)=>{
                              if(r.plannCode == `${this.datePicker.EmployeeCode}` && index == 0){
                                this.average[4][`${category[x]}`] = index+1
                              }
                              else if (r.plannCode == `${this.datePicker.EmployeeCode}` && index > 0){
                                this.average[4][`${category[x]}`] = ctr +1
                              }
                            })
                          ctr +=  rec.data.length
                        })
                      }
//National
              let nationplan=overall[0].plannerCode
              let nationarr=overall[0].countDocu
                for(let x=0;x<=nationarr.length-1;x++){
                    this.average[5].decidedland+=parseInt(nationarr[x].decidedlandDays?nationarr[x].decidedlandDays:0)
                    this.average[5].stopcheck+=parseInt(nationarr[x].stopcheck?nationarr[x].stopcheck:0)
                    this.average[5].finalPlan+=parseInt(nationarr[x].finalPlan?nationarr[x].finalPlan:0)
                    this.average[5].joutou+=parseInt(nationarr[x].JoutouDays?nationarr[x].JoutouDays:0)
                }
                this.average[5].decidedland =  this.tofixed2((this.average[5].decidedland / nationarr.length))
                this.average[5].stopcheck =   this.tofixed2((this.average[5].stopcheck / nationarr.length))
                this.average[5].finalPlan =   this.tofixed2((this.average[5].finalPlan / nationarr.length))
                this.average[5].joutou =   this.tofixed2((this.average[5].joutou / nationarr.length))
                  let nationArt=[]
                    for(let x=0;x<=nationplan.length-1;x++){
                       let obj={plannCode:'',decidedland:0,stopcheck:0,finalPlan:0,joutou:0}
                        if(nationplan[x]){
                          for(let i=0;i<=nationplan[x].length-1;i++){
                            obj.plannCode=nationplan[x][0].EmployeeCode
                            obj.decidedland+=nationplan[x][i].decidedlandDays?parseInt(nationplan[x][i].decidedlandDays):0
                            obj.stopcheck+=nationplan[x][i].stopcheck?parseInt(nationplan[x][i].stopcheck):0
                            obj.finalPlan+=nationplan[x][i].finalPlan?parseInt(nationplan[x][i].finalPlan):0
                            obj.joutou+=nationplan[x][i].JoutouDays?parseInt(nationplan[x][i].JoutouDays):0
                          }
                          obj.decidedland = parseFloat(this.tofixed2((obj.decidedland / nationplan[x].length)))
                          obj.stopcheck =parseFloat( this.tofixed2((obj.stopcheck / nationplan[x].length)))
                          obj.finalPlan =parseFloat( this.tofixed2((obj.finalPlan / nationplan[x].length)))
                          obj.joutou = parseFloat(this.tofixed2((obj.joutou / nationplan[x].length)))
                      }
                      nationArt.push(obj)
                    }
                  for(let x =0; x<= category.length -1; x++){
                    //  console.log('----------------------',category[x],'--------------------------')
                    this.NationLength=  _.orderBy(nationArt ,[`${category[x]}`] ,['asc'])
                    let arr=this.NationLength.reduce((total,item)=>{
                        let index =total.findIndex(y=> y.value == item[`${category[x]}`] ) 
                        if(index > -1 ){
                            total[index].data.push(item)
                          return total
                        }
                        else{
                          total.push(
                           {
                            value:item[`${category[x]}`],
                            data:[item]
                           }
                          )
                          return total
                        }
                    },[])
                    let ctr = 0 
                    arr.filter((rec,index)=>{
                      // console.log(rec)
                      rec.data.forEach((r)=>{
                        if(r.plannCode == `${this.datePicker.EmployeeCode}` && index == 0){
                          this.average[6][`${category[x]}`] = index+1
                        }
                        else if (r.plannCode == `${this.datePicker.EmployeeCode}` && index > 0){
                          this.average[6][`${category[x]}`] = ctr +1
                        }
                      })
                    ctr +=  rec.data.length
                  })  
                }
              } 
            })//res.data
            this.$nextTick(() => {
                  this.plannerData.getTableHeight()
                  this.setSecondTheadTop()
                  this.plannerData.getColumnWidth(2, 4)
                });
              }else{
                Swal.fire({
                    icon: "warning",
                    // title: "Oops...",
                    title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
                    text: `${this.$i18n.locale =='ja'?' 開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' :'Please re-enter so that the period from the start date to the end date is within one year.!'}`,
                });
                  this.loading = false;
                  return false
                }
              }catch (error) {
                this.loading=false;
                alert('Error fetching data:', error);
              }
            },
            getPlannerCode() {
              if( parseInt(moment.duration(moment(this.plannerCodeRange.toDate,"YYYY-MM-DD").diff(moment(this.plannerCodeRange.fromDate,"YYYY-MM-DD"))).asDays()) <= 365   ){
                this.user={}
                this.user=this.userInfo  
                if(this.userInfo.User_Category != 'admin' && this.userInfo.User_Category != 'GC Admin'){
                  this.planners=[]
                  this.plannerCodeRange.PlannerCode=this.userInfo.Emp_code
                  let obj={
                    PlannerCode:this.userInfo.Emp_code,
                    fromDate:this.plannerCodeRange.fromDate,
                    toDate:this.plannerCodeRange.toDate
                  }
                  this.plannersLoading=true
                  this.plannersCodefunction.getplannersCode(this.aws,obj,this.apiKey).then((r)=>{
                    this.planners=r.data
                    let a = this.planners.findIndex(data=> data.EmployeeCode == obj.PlannerCode)
                      if(a == -1){
                        Swal.fire({
                          icon: "error",
                          title: "Error!",
                          text: `Planner ${this.userInfo.Emp_code} dont have Data`,
                        });
                        this.planners.push({
                          EmployeesData:`${this.userInfo.Emp_code +' - '+this.userInfo.Name}`,
                          EmployeeCode:this.userInfo.Emp_code,
                          EmployeeName:this.userInfo.Name
                        })
                      }
                    this.plannersLoading=r.loading
                  }) 
                }else{
                  this.SalesOfficeArr=[]
                  this.planners=[]
                  this.SalesLoading=true;
                  this.SO=''
                  let obj={
                    fromDate:this.plannerCodeRange.fromDate,
                    toDate:this.plannerCodeRange.toDate,
                    userCategory:this.userInfo.User_Category,
                    SalesOfficeName:this.userInfo.User_Category
                  }
                  this.salesOfficefunction.getSalesOffice(obj,this.aws,this.apiKey).then((r)=>{
                      this.SalesOfficeArr = r.data.filter((x) => x.EmployeeName !== null).sort((a, b) => a.sort - b.sort);
                      this.SalesLoading = r.data.loading;
                  })
                }
              }else{
                Swal.fire({
                  icon: "warning",
                  title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
                  text: `${this.$i18n.locale =='ja'?' 開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' :'Please re-enter so that the period from the start date to the end date is within one year.!'}`,
                });
                return false
              }
            },
            getPlannerData(){
              this.planners=[]
              this.cadData=[]
              this.plannerCodeRange.Planner.EmployeesData=''
              this.planners = this.SalesOfficeArr.filter(r=>{
                if( r.SalesOfficeName == this.SO.SalesOfficeName){
                    r.EmployeesData = `${r.EmployeeCode +' - ' +r.EmployeeName}`
                  return r
                }
              })
            },
            splitChar(data) {
              if (data.includes('(')) {
                const parts = data.split('(');
                return [parts[0], '(' + parts.slice(1).join('(')];
              } else {
                return data;
              }
            },
            copyText(textToCopy, index) {
            this.$set(this.showText, index, true);

            const constructionCodeElement = this.$el.querySelector(`#constructionCode-${index}`);
            constructionCodeElement.classList.add('simulate-text-highlight');

            // Remove the highlight for the last copied index (if any)
            if (this.lastCopiedIndex !== null) {
              const lastCopiedElement = this.$el.querySelector(`#constructionCode-${this.lastCopiedIndex}`);
              lastCopiedElement.classList.remove('simulate-text-highlight');
            }

            setTimeout(() => {
              this.$set(this.showText, index, false);
              constructionCodeElement.classList.remove('simulate-text-highlight');
            }, 800);

            const textarea = document.createElement('textarea');
            textarea.value = textToCopy;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);

            // Update the last copied index
            this.lastCopiedIndex = index;
          },
          hoverColumn(val, index){
            this.$set(this.copyIcon, index, val);
          },
          setSecondTheadTop() {
            this.$nextTick(() => {
              if(this.$refs.firstThead){
                const firstTheadRect = this.$refs.firstThead.getBoundingClientRect();
                  const firstTheadHeight = firstTheadRect.height;
                  this.secondTheadTop = firstTheadHeight;
              }
            })
          },
        },
        mounted() {
          this.getPlannerCode()
          window.addEventListener('resize', () =>{
            this.plannerData.getTableHeight()
            this.setSecondTheadTop()
            this.plannerData.getColumnWidth(2, 4)
          })
        },
        computed: {
            monthRestriction() {
              return moment(this.plannerCodeRange.fromDate).add(12,'months').endOf('month').format('YYYY-MM-DD')
            },
            userInfo() {
              return this.$root.decryptedUser;
            }
        },
        watch: {
          '$i18n.locale'() {
            if (this.cadData.length !== 0) {
              this.setSecondTheadTop()
            }
          },
        },
        beforeDestroy() {
          window.removeEventListener('resize', this.setSecondTheadTop);
        }
    }
</script>

<style lang="scss" scoped>
::selection {
  background-color: #3399ff;  /* Change the background color to simulate the Windows highlight effect */
  color: white;  /* Change the text color for better contrast */
}
.sticky-thead {
  position: sticky;
  z-index: 1;
  background-color: white;
}
.simulate-text-highlight {
  background-color: #3399ff;  /* Change the background color to simulate the Windows highlight effect */
  color: white;  /* Change the text color for better contrast */
}
  .TableStyle {
    border: 1px solid black;
  }
  .last-row td{
    background: white;
  }
  .center {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}
 .backColor {
  background-color: rgb(163, 147, 147);
}

.body {
  overflow: hidden; /* Hide scrollbars */
}

.cnter{
    position:absolute;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.header {
  text-wrap:wrap;
  background-color: #01579b; 
  color: white !important;
}
</style>